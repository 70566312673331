<template>
    <div>
        <b-card :title="cardTitle" class="rounded-lg">
            <b-card-text> {{ $t('HomeCard.Subtitle') }} </b-card-text>
            <b-card-text>
                <b-row>
                    <Loading v-if="statsLoading" />

                    <template v-else>
                        <b-col xl="12" sm="12" v-if="stats.length == 0">
                            <b-card
                                class="shadow"
                                no-body
                                style="height: 100px"
                            >
                                <b-card-body class="shadow rounded-lg">
                                    <b-media no-body class="">
                                        <b-media-aside class="mr-2">
                                            <b-avatar
                                                size="60"
                                                variant="light-primary"
                                            >
                                                <feather-icon
                                                    size="40"
                                                    icon="SmileIcon"
                                                />
                                            </b-avatar>
                                        </b-media-aside>
                                        <b-media-body class="my-auto">
                                            <h4 class="font-weight-bolder mb-0">
                                                {{ $t('HomeCard.EmptyStats') }}
                                            </h4>
                                        </b-media-body>
                                    </b-media>
                                </b-card-body>
                            </b-card>
                        </b-col>

                        <template v-else>
                            <b-col
                                v-for="(item, index) in stats"
                                :key="index"
                                xl="3"
                                sm="6"
                            >
                                <b-card
                                    class="shadow"
                                    no-body
                                    style="height: 100px"
                                >
                                    <b-card-body
                                        class="shadow rounded-lg"
                                        style="
                                            padding-right: 10px;
                                            padding-left: 10px;
                                        "
                                    >
                                        <b-media no-body class="">
                                            <b-media-aside class="mr-2">
                                                <b-avatar
                                                    size="48"
                                                    variant="light-primary"
                                                >
                                                    <feather-icon
                                                        size="24"
                                                        icon="FileTextIcon"
                                                    />
                                                </b-avatar>
                                            </b-media-aside>
                                            <b-media-body class="my-auto">
                                                <b-card-text
                                                    class="font-small-3 mb-0"
                                                >
                                                    {{
                                                        $t(
                                                            `HomeCard.Stats.${item.label}`
                                                        )
                                                    }}
                                                </b-card-text>
                                                <h4
                                                    class="font-weight-bolder mb-0"
                                                >
                                                    {{ item.value }}
                                                </h4>
                                            </b-media-body>
                                        </b-media>
                                    </b-card-body>
                                </b-card>
                            </b-col>
                        </template>
                    </template>
                </b-row>
            </b-card-text>

            <b-card-text>
                <b-row>
                    <b-col cols="12">
                        <b-card no-body class="shadow">
                            <b-card-body class="shadow rounded-lg">
                                <Loading v-if="current_package.length == 0" />
                                <b-row v-else>
                                    <b-col xl="3" sm="6">
                                        <img
                                            width="200px"
                                            src="@/assets/icon/subscription-outline.png"
                                            alt=""
                                        />
                                    </b-col>
                                    <b-col
                                        xl="9"
                                        sm="6"
                                        class="d-flex my-auto align-content-center flex-column"
                                        style="gap: 0.5rem"
                                    >
                                        <h4 class="font-weight-bolder mb-0">
                                            {{ $t('HomeCard.CurrentPlan') }}
                                        </h4>
                                        <ul class="pl-2 mb-0">
                                            <span
                                                v-for="(
                                                    data, index
                                                ) in current_package"
                                                :key="index"
                                            >
                                                <li class="font-weight-bolder">
                                                    {{
                                                        data.package
                                                            .package_name
                                                    }}
                                                </li>

                                                <li
                                                    v-for="(
                                                        featureItem, index
                                                    ) in data[feature]"
                                                    :key="index"
                                                    class="font-weight-bolder"
                                                >
                                                    {{ featureItem }}
                                                </li>
                                            </span>
                                        </ul>

                                        <div
                                            class="d-flex justify-content-lg-end justify-content-center"
                                        >
                                            <b-button
                                                :to="{name: 'subscription'}"
                                                v-ripple.400="
                                                    'rgba(255, 255, 255, 0.15)'
                                                "
                                                variant="primary"
                                            >
                                                {{
                                                    $t(
                                                        'HomeCard.UpgradePlanBtn'
                                                    )
                                                }}
                                            </b-button>
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-card-body>
                        </b-card>
                    </b-col>
                </b-row>
            </b-card-text>
        </b-card>
    </div>
</template>

<script>
import Ripple from 'vue-ripple-directive';
import {getUserData} from '@/auth/utils';
import {getSubscribersMixins} from '@/mixins/getSubscribersMixins';
import useAppConfig from '@core/app-config/useAppConfig';

export default {
    mixins: [getSubscribersMixins],
    directives: {
        Ripple,
    },
    data() {
        return {
            userData: getUserData(),
            current_package: [],
            stats: [],
            config: useAppConfig(),
            statsLoading: true,
            date: new Date(),
        };
    },
    computed: {
        cardTitle() {
            return `${this.$i18n.t('Message.welcome')} ${this.$i18n.t(
                'Message.back'
            )}, ${this.userData.full_name}!`;
        },
        feature() {
            if (this.getLang == '') {
                return this.swedishLang ? 'swe_features' : 'features';
            } else return this.getLang == 'sv' ? 'swe_features' : 'features';
        },
        getLang() {
            return this.config.lang.value;
        },
    },

    methods: {
        getStats() {
            this.stats = [];

            this.$useJwt
                .stats({
                    params: {
                        month: this.date.getMonth() + 1,
                    },
                })
                .then(res => {
                    const {results} = res.data;

                    results.forEach(element => {
                        this.stats.push(
                            {
                                label: 'bookkeep',
                                value: element.no_of_invoices_booked,
                            },
                            {
                                label: 'sentdirectly',
                                value: element.no_of_invoices_sent_regular,
                            },
                            {
                                label: 'sentfortnox',
                                value: element.no_of_invoices_sent_nox,
                            },
                            {
                                label: 'total',
                                value: element.no_of_total_invoices_handled,
                            }
                        );
                    });
                })
                .finally(() => (this.statsLoading = false));
        },
        async getCurrentPlanFeature() {
            this.current_package = [];
            let currentPlanId = await this.getCurrentPlan();
            this.$useJwt.packages().then(res => {
                const {results} = res.data;

                this.current_package = results.filter(element => {
                    if (element.package !== null)
                        return element.package.id == currentPlanId[0];
                });
            });
        },

        init() {
            this.getStats();
            this.getCurrentPlanFeature();
        },
    },

    mounted() {
        this.init();
    },
};
</script>

<style></style>
